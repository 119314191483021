<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisHeader="'d-none'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalConferenciaEdicao"
    >
      <div slot="body" class="col-12" @keydown.esc="fechar()">
        <div class="row">
          <div class="form-group col-12">
            <p class="titulo-dados-adicionais">Transporte</p>
            <label for="selectTipoFrete">Tipo do Frete:</label>
            <select
              id="lmxta-dadosadicionais-select-tipofrete"
              name="selectTipoFrete"
              class="form-control form-control-sm"
              v-tooltip.bottom="apresentarTooltip('Selecione o tipo do frete')"
              v-model="TipoFrete"
            >
              <option value="" selected>Selecione o tipo do frete</option>
              <option v-for="(TipoFrete, indice) in tiposFrete" :value="TipoFrete.Id" :key="indice">
                {{ TipoFrete.Descricao }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12">
            <label for="selectTransportador">Transportador:</label>
            <select
              id="lmxta-dadosadicionais-select-transportador"
              name="selectTransportador"
              class="form-control form-control-sm"
              v-tooltip.bottom="apresentarTooltip('Selecione o transportador')"
              v-model="Transportador"
            >
              <option value="" selected>Nenhum</option>
              <option v-for="(Transportador, indice) in transportadores" :value="Transportador.Codigo" :key="indice">
                {{ Transportador.Nome }}
                ({{ Transportador.Codigo }})
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label>Data Saída:</label>
            <div class="input-group input-group-sm">
              <pikaday-vue-datepicker
                class="form-control"
                nome="inputDataSaida"
                v-model="DataSaida"
                botao="lmxta-dadosadicionais-btn-datasaida"
              ></pikaday-vue-datepicker>
              <span id="lmxta-dadosadicionais-btn-datasaida" class="input-group-append">
                <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent" />
              </span>
            </div>
          </div>

          <div class="form-group col-12 col-md-6">
            <label>Hora Saída:</label>
            <input
              id="lmxta-dadosadicionais-input-horasaida"
              name="inputHoraSaida"
              class="form-control form-control-sm"
              type="time"
              v-model="HoraSaida"
              value
              maxlength="5"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="inputQuantidade">Quantidade:</label>
            <money
              type="tel"
              id="lmxta-dadosadicionais-input-quantidade"
              name="inputQuantidade"
              class="form-control form-control-sm text-right"
              value="0"
              v-tooltip.bottom="apresentarTooltip('Insira a quantidade a ser transportada')"
              v-model="Quantidade"
              maxlength="10"
              v-bind="money"
            ></money>
          </div>

          <div class="form-group col-12 col-md-6">
            <label for="inputEspecie">Espécie:</label>
            <input
              id="lmxta-dadosadicionais-input-especie"
              name="inputEspecie"
              class="form-control form-control-sm"
              type="text"
              value
              v-tooltip.bottom="apresentarTooltip('Insira a espécie a ser transportada')"
              v-model="Especie"
              maxlength="10"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="inputPesoBruto">Peso bruto:</label>
            <money
              id="lmxta-dadosadicionais-input-pesobruto"
              type="tel"
              class="form-control form-control-sm text-right"
              v-model="PesoBruto"
              v-tooltip.bottom="apresentarTooltip('Insira o peso bruto')"
              maxlength="10"
              v-bind="peso"
            ></money>
          </div>

          <div class="form-group col-12 col-md-6">
            <label for="inputPesoLiquido">Peso líquido:</label>
            <money
              id="lmxta-dadosadicionais-input-pesoliquido"
              type="tel"
              class="form-control form-control-sm text-right"
              v-model="PesoLiquido"
              v-tooltip.bottom="apresentarTooltip('Insira o peso líquido')"
              maxlength="10"
              v-bind="peso"
            ></money>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label>Marca:</label>
            <input
              id="lmxta-dadosadicionais-input-marca"
              class="form-control form-control-sm"
              type="text"
              value
              v-tooltip.bottom="apresentarTooltip('Insira a marca')"
              v-model="Marca"
              maxlength="60"
            />
          </div>

          <div class="form-group col-12 col-md-6">
            <label>Número:</label>
            <input
              id="lmxta-dadosadicionais-input-numero"
              class="form-control form-control-sm"
              type="text"
              value
              v-tooltip.bottom="apresentarTooltip('Insira o número')"
              v-model="Numero"
              maxlength="10"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12">
            <p class="titulo-dados-adicionais">Informações adicionais</p>
            <label>Observação padrão:</label>
            <select
              id="lmxta-dadosadicionais-select-observacaopadrao"
              class="form-control form-control-sm"
              v-tooltip.bottom="apresentarTooltip('Selecione a observação padrão')"
              v-model="ObservacaoPadrao"
            >
              <option value="" selected>Nenhuma</option>
              <option v-for="(ObservacaoPadrao, indice) in observacoesPadrao" :value="ObservacaoPadrao.Descricao" :key="indice">
                {{ ObservacaoPadrao.Titulo }}
                ({{ ObservacaoPadrao.Id }})
              </option>
            </select>

            <small>{{ ObservacaoPadrao ? ObservacaoPadrao.Descricao : '' }}</small>
          </div>
          
          <div class="form-group col-12">
            <label>Centro de Custo da Devolução:</label>
            <select
              id="lmxta-dadosadicionais-select-centro-custo"
              class="form-control form-control-sm"
              v-tooltip.bottom="apresentarTooltip('Selecione um centro de custo')"
              v-model="IdCentroCustoDevolucao"
            >
              <option value="null" selected>Conforme a operação padrão</option>
              <option v-for="(centroCusto, indice) in centrosCusto" :value="centroCusto.Id" :key="indice">
                {{ centroCusto.Nome }}
                ({{ centroCusto.Id }})
              </option>
            </select>
          </div>

          <div class="form-group col-12">
            <label for="txtComplemento">Complemento:</label>
            <textarea
              id="lmxta-dadosadicionais-input-complemento"
              class="form-control form-control-sm"
              maxlength="2500"
              v-tooltip.bottom="apresentarTooltip('Informe o complemento da nota')"
              v-model="Complemento"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between col-12">
            <button id="lmxta-dadosadicionais-btn-cancelar" type="button" class="btn btn-default" @click="fechar">
              <span class="text-center">Cancelar</span>
            </button>
            <button id="lmxta-dadosadicionais-btn-salvar" type="button" class="btn btn-primary" @click="salvar">
              <span class="text-center">Salvar</span>
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import pikadayVueDatepicker from '@/components/shared/PikadayVueDatepicker';
  import { Money } from 'v-money';
  import { mapState } from 'vuex';
  import dateHelper from '@/common/date';
  import VTooltip from 'v-tooltip';
  import Vue from 'vue';
  import loading from '@/common/loading';
  import swal from '@/common/alerts';

  Vue.use(VTooltip);

  export default {
    name: 'DevolucaoConferenciaDadosAdicionais',

    components: {
      pikadayVueDatepicker,
      Money,
    },

    data() {
      return {
        TipoFrete: '',
        Transportador: '',
        DataSaida: null,
        HoraSaida: null,
        Quantidade: 0,
        Especie: null,
        PesoBruto: 0,
        PesoLiquido: 0,
        Marca: null,
        Numero: null,
        ObservacaoPadrao: '',
        Complemento: null,
        IdCentroCustoDevolucao: null,
        money: {
          decimal: ',',
          thousands: '.',
        },
        peso: {
          decimal: ',',
          thousands: '.',
          precision: 3,
        }
      };
    },

    computed: {
      ...mapState({
        tiposFrete: (state) => state.devolucao.Configuracoes.listasGerais.TiposFrete,
        transportadores: (state) => state.devolucao.Configuracoes.listasGerais.Transportadores,
        observacoesPadrao: (state) => state.devolucao.Configuracoes.listasGerais.ObservacoesPadrao,
        dadosAdicionais: (state) => state.devolucao.Conferencia.DadosAdicionais,
        produtos: (state) => state.devolucao.ProdutosAssociacaoManual,
        fornecedor: (state) => state.devolucao.Identificacao.Fornecedor,
        centrosCusto: (state) => state.devolucao.Configuracoes.listasGerais.CentrosCusto,
        preferenciaEmpresa: (state) => state.devolucao.Configuracoes.preferenciasEmpresa,
      }),

      bootstrapConflict() {
        return {
          template:
            '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },
    },

    mounted() {
      this.TipoFrete = this.dadosAdicionais.TipoFrete;
      this.Transportador = this.dadosAdicionais.Transportador;
      this.DataSaida = this.dadosAdicionais.DataSaida;
      this.HoraSaida = this.dadosAdicionais.HoraSaida;
      this.Quantidade = this.dadosAdicionais.Quantidade;
      this.Especie = this.dadosAdicionais.Especie;
      this.PesoBruto = this.dadosAdicionais.PesoBruto;
      this.PesoLiquido = this.dadosAdicionais.PesoLiquido;
      this.Marca = this.dadosAdicionais.Marca;
      this.Numero = this.dadosAdicionais.Numero;
      this.ObservacaoPadrao = this.dadosAdicionais.ObservacaoPadrao;
      this.Complemento = this.dadosAdicionais.Complemento;
      this.IdCentroCustoDevolucao = this.dadosAdicionais?.IdCentroCustoDevolucao ?? this.preferenciaEmpresa?.IdCentroCustoDevolucao ?? null;

      if(!this.camposPreenchidos())
      this.obterDadosComplementares();
    },

    methods: {
      apresentarTooltip(mensagem) {
        return { content: mensagem, ...this.bootstrapConflict };
      },

      camposPreenchidos(){
        if(this.TipoFrete == "" && 
        this.Transportador == "" && 
        this.DataSaida == undefined &&
        this.HoraSaida == undefined &&
        this.Quantidade == undefined &&
        this.Especie == undefined && 
        this.PesoBruto == undefined && 
        this.PesoLiquido == undefined &&
        this.Marca == undefined &&
        this.Numero == undefined && 
        this.ObservacaoPadrao == "" &&
        this.Complemento == undefined
      )
        return false;
        else
        return true;
      },

      salvar() {
        this.dadosAdicionais.TipoFrete = this.TipoFrete;
        this.dadosAdicionais.Transportador = this.Transportador;
        this.dadosAdicionais.Quantidade = this.Quantidade;
        this.dadosAdicionais.Especie = this.Especie;
        this.dadosAdicionais.PesoBruto = this.PesoBruto;
        this.dadosAdicionais.PesoLiquido = this.PesoLiquido;
        this.dadosAdicionais.Marca = this.Marca;
        this.dadosAdicionais.Numero = this.Numero;
        this.dadosAdicionais.ObservacaoPadrao = this.ObservacaoPadrao;
        this.dadosAdicionais.Complemento = this.Complemento;
        this.dadosAdicionais.IdCentroCustoDevolucao = this.IdCentroCustoDevolucao;

        if (this.DataSaida) {
          this.dadosAdicionais.DataSaida = dateHelper.converterData(this.DataSaida);

          if (!this.HoraSaida) {
            this.HoraSaida = dateHelper.obterHorarioAtual();
          }

          this.dadosAdicionais.HoraSaida = this.HoraSaida;
        }

        this.fechar();
      },

      fechar() {
        this.$emit('fechar');
      },
      obterDadosComplementares() {
        loading.exibir();
        var listProdutos = this.produtos.map((item) => {
          return { IdProduto: item.CodigoProduto, Quantidade: item.Quantidade };
        });
        let command = {
          IdCliente: this.fornecedor.Codigo,
          Produto: listProdutos,
        };

        this.$store
          .dispatch('devolucao/obterDadosComplementares', command)
          .then((response) => {
            this.dadosComplementares = response.data;
            this.PesoLiquido = this.dadosComplementares.Transporte.TotalPesoLiquido;
            this.PesoBruto = this.dadosComplementares.Transporte.TotalPesoBruto;
          })
          .catch(() => swal.exibirMensagemErro('Ocorreu um problema ao obter os dados complementares'))
          .finally(() => loading.ocultar());
      },
    },
  };
</script>

<style scoped>
  .titulo-dados-adicionais {
    color: #626262;
    font-family: Dosis, sans-serif;
    font-size: 1.125rem;
    text-transform: uppercase;
    padding: 5px 0px;
    margin-bottom: 5px;
    cursor: default;
  }

  .borda-dados-adicionais {
    padding: 5px;
    border: 0.5px solid #e4dfdf;
    border-radius: 5px;
  }
</style>
